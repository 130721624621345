<template>
    <div>
        <search-area title="35 Unread Messages" height="80">
            <template v-slot:item>
                <v-text-field class="search-input"
                              outlined
                              label="Search neighbourhood, city or state"
                >
                    <template v-slot:prepend-inner>
                        <div class="prepend-div ">
                            <v-icon size="25" color="primary">mdi-magnify</v-icon>
                        </div>

                    </template>
                </v-text-field>
            </template>
        </search-area>
        <profile-list-card :data="{title:item.title,subtitle:item.subtitle,location:item.location,icon:'mdi-chat-outline',isActive:item.isActive}" v-for="(item,i) in connect_list2" :key="i" :show_subtitle="false"></profile-list-card>

    </div>
</template>

<script>
    export default {
        name: "UserList",
        data() {
            return {
                connect_list2:[
                {title:"Wale Ade",subtitle:"Agent",location:"Abuja,NG",icon:"mdi-chat-outline",isActive:true},
                {title:"Wale Ade",subtitle:"Agent",location:"Oyo,NG",icon:"mdi-chevron-right",isActive:false},
                {title:"Wale Ade",subtitle:"Agent",location:"Ogun,NG",icon:"mdi-chevron-right",isActive:true}

                ]
            }
        },
    }
</script>

<style scoped>
    .v-text-field.v-text-field--enclosed {
        margin: 10px;
        padding: 0;
        /*border-radius: 25px;*/
    }
    .search-input{
        border-radius: 25px;

    }
    .v-label {
        /*font-size: 16px;*/
        font-size:12px !important;
    }

    .prepend-div{
        background: rgba(0, 117, 242, 0.05);
        text-align: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        padding: 5px;
        margin-top: -5px;
    }
</style>